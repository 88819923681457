import { EUNOIA_API_CONNECTOR } from "./apiConnector";
import { EUNOIA_CONFIG } from "./apiConfig";
import store from "../store/index";

export const getProductDetails = async (payload) => {
    const isHQ = store.getters.isHQ;
	let code = EUNOIA_CONFIG.brandCode;
    if(payload.brandCode) code = payload.brandCode;
	if(isHQ) code = "hq$" + code;

	var path = `/menu/${code}?`;
    if(payload.storeId) path = `/menu/${code}/${payload.storeId}?`;
	const { post } = EUNOIA_API_CONNECTOR({});
	var params = {
		app: EUNOIA_CONFIG.app,
	};
    if(payload.productId) params.productId = payload.productId;
	try {
		const data = await post(path, new URLSearchParams(params));
		return Promise.resolve(data);
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getMenu = async (payload) => {
	const isHQ = store.getters.isHQ;
	let code = EUNOIA_CONFIG.brandCode;
    if(payload.brandCode) code = payload.brandCode;
	if(isHQ) code = "hq$" + code;
	var path = `/menu/${code}?`;
    if(payload.storeId) path = `/menu/${code}/${payload.storeId}?`;
	const { post } = EUNOIA_API_CONNECTOR({});
	var params = {
		app: EUNOIA_CONFIG.app,
	};
	try {
		const data = await post(path, new URLSearchParams(params));
		return Promise.resolve(data);
	} catch (error) {
		return Promise.reject(error);
	}
};

export const paymayaCreateCheckout = async (payload) => {
    const isHQ = store.getters.isHQ;
	let code = EUNOIA_CONFIG.brandCode;
	if(isHQ) code = "hq$" + code;
	var path = `/paymaya/createCheckout/${code}?`;
	const { post } = EUNOIA_API_CONNECTOR({});
	var params = {
		app: EUNOIA_CONFIG.app,
		authToken: store.getters.getEunoiaToken,
	}
	params = { 
		...params,
		...payload
	}
	try {
		const data = await post(path, new URLSearchParams(params));
		return Promise.resolve(data);
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getInventory = async (outletCode, productId) => {
  // const isHQ = store.getters.isHQ;
  let code = "hq$" + EUNOIA_CONFIG.brandCode;
  // if(isHQ) code = store.getters.getCurrentStore.brandCode;
  var path = `/inventory/${code}?`;
  const { post } = EUNOIA_API_CONNECTOR({});
  var params = {
    app: EUNOIA_CONFIG.app,
    authToken: store.getters.getEunoiaToken,
    outlet: outletCode || "",
    productId: productId || "",
  };
  try {
    const data = await post(path, new URLSearchParams(params));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};

export const getFavorites = async () => {
	const isHQ = store.getters.isHQ;
	let code = EUNOIA_CONFIG.brandCode;
	if(isHQ) code = "hq$" + code;
	var path = `/customer/favourites/${code}?`;
	const { post } = EUNOIA_API_CONNECTOR({});
	var params = {
		app: EUNOIA_CONFIG.app,
		authToken: store.getters.getEunoiaToken,
	};
	try {
		const data = await post(path, new URLSearchParams(params));
		return Promise.resolve(data);
	} catch (error) {
		return Promise.reject(error);
	}
};

export const addFavorite = async (productId) => {
	const isHQ = store.getters.isHQ;
	let code = EUNOIA_CONFIG.brandCode;
	if(isHQ) code = "hq$" + code;
	var path = `/customer/favourite/${code}?`;
	const { post } = EUNOIA_API_CONNECTOR({});
	var params = {
		app: EUNOIA_CONFIG.app,
		product: productId,
		authToken: store.getters.getEunoiaToken,
	};
	try {
		const data = await post(path, new URLSearchParams(params));
		return Promise.resolve(data);
	} catch (error) {
		return Promise.reject(error);
	}
};

export const unFavorite = async (productId) => {
	const isHQ = store.getters.isHQ;
	let code = EUNOIA_CONFIG.brandCode;
	if(isHQ) code = "hq$" + code;
	var path = `/customer/unfavourite/${code}?`;
	const { post } = EUNOIA_API_CONNECTOR({});
	var params = {
		app: EUNOIA_CONFIG.app,
		product: productId,
		authToken: store.getters.getEunoiaToken,
	};
	try {
		const data = await post(path, new URLSearchParams(params));
		return Promise.resolve(data);
	} catch (error) {
		return Promise.reject(error);
	}
};

export const postOutletOrder = async (payload, outletCode) => {
	let hqCode = EUNOIA_CONFIG.brandCode + "$";
	var path = `/order/${hqCode}${outletCode}/outlet`;
	let orderData = payload;
	orderData.app = EUNOIA_CONFIG.app;
	orderData.authToken = store.getters.getEunoiaToken;
	let body = { body: JSON.stringify(orderData) };
	const { post } = EUNOIA_API_CONNECTOR({});
	try {
		const data = await post(path, new URLSearchParams(body));
		return Promise.resolve(data);
	} catch (error) {
		return Promise.reject(error);
	}
};

export const postOrder = async (payload) => {
	let code = EUNOIA_CONFIG.brandCode;
	var path = `/order/${code}`;
	let orderData = payload;
	orderData.app = EUNOIA_CONFIG.app;
	orderData.authToken = store.getters.getEunoiaToken;
	orderData.store = store.getters.getCurrentStore ? store.getters.getCurrentStore.id : "";
	let body = { body: JSON.stringify(orderData) };
	const { post } = EUNOIA_API_CONNECTOR({});
	try {
		const data = await post(path, new URLSearchParams(body));
		return Promise.resolve(data);
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getOrder = async (number) => {
	const isHQ = store.getters.isHQ;
	let code = EUNOIA_CONFIG.brandCode;
	const authToken = store.getters.getEunoiaToken;
	if(isHQ) {
		code = "hq$" + code;
	}
	var path = `/orders/${code}`;
	let params = {
		extraPayload: {
			number: number,
		},
        app: EUNOIA_CONFIG.app,
		authToken: authToken
	}
	const { get } = EUNOIA_API_CONNECTOR(params);
	try {
		const data = await get(path, new URLSearchParams(params));
		return Promise.resolve(data);
	} catch (error) {
		return Promise.reject(error);
	}
};

export const getCart = async (cartId, outletCode) => {
  const isHQ = store.getters.isHQ;
  let code = EUNOIA_CONFIG.brandCode;
  const authToken = store.getters.getEunoiaToken;
  if (isHQ) {
    if (outletCode) code = `${code}$${outletCode}`;
    else code = code + "$hq";
  }
  var path = `/order/${code}/cart`;
  let params = {
    cartId: cartId,
    app: EUNOIA_CONFIG.app,
  };
  if (authToken) params.authToken = authToken;
  const { post } = EUNOIA_API_CONNECTOR({});
  try {
    const data = await post(path, new URLSearchParams(params));
    return Promise.resolve(data);
  } catch (error) {
    return Promise.reject(error);
  }
};
