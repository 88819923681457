import { getLayer, getStores, getPromotions, getOrders, getBookings } from "@/connector/storeConnector";

export default {
	getLayer: async () => {
		try {
			let result = await getLayer();
			return Promise.resolve(result);
		} catch (error) {
			return Promise.reject(error);
		}
	},
	retrieveStores: async () => {
		try {
			let result = await getStores();
			return Promise.resolve(result);
		} catch (error) {
			return Promise.reject(error);
		}
	},
	retrievePromotions: async () => {
		try {
			let result = await getPromotions();
			return Promise.resolve(result);
		} catch (error) {
			return Promise.reject(error);
		}
	},
	retrieveOrders: async () => {
		try {
			let result = await getOrders();
			return Promise.resolve(result);
		} catch (error) {
			return Promise.reject(error);
		}
	},
	retrieveBookings: async () => {
		try {
			let result = await getBookings();
			return Promise.resolve(result);
		} catch (error) {
			return Promise.reject(error);
		}
	}
};
